import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
`;
export default function Money() {
  return (
    <Wrapper>
      <svg
        width="60"
        height="60"
        viewBox="0 0 130 130"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.757986 40.0168L31.1487 33.3247C27.8159 19.6648 18.8261 10.1505 10.6715 11.8894C2.47701 13.637 -1.85166 26.1036 0.757986 40.0168Z"
          fill="#29AC84"
        />
        <path
          d="M30.8128 33.0994C26.391 15.8542 16.2576 11.5429 11.7436 11.5429C28.1413 8.22653 61.6552 1.37267 64.5294 0.488306C67.4036 -0.396063 69.5039 0.119819 70.1948 0.488306C87.6611 4.91015 103.359 56.3141 109.024 81.4633H43.6638L30.8128 33.0994Z"
          fill="#86CEB8"
        />
        <g filter="url(#filter0_d_101_9)">
          <path
            d="M68.5404 54.3665C76.6985 54.3665 83.312 48.5275 83.312 41.3247C83.312 41.0705 83.3037 40.818 83.2875 40.5674H109.016C116.022 40.5674 121.701 46.2934 121.701 53.3568V103.841C121.701 110.905 116.022 116.631 109.016 116.631H27.8978C20.892 116.631 15.2126 110.905 15.2126 103.841V53.3568C15.2126 46.2934 20.892 40.5674 27.8978 40.5674H53.7934C53.7771 40.818 53.7689 41.0705 53.7689 41.3247C53.7689 48.5275 60.3823 54.3665 68.5404 54.3665Z"
            fill="#7798FC"
          />
        </g>
        <path
          d="M45.9134 66.0569C45.7762 65.4646 46.1102 64.8644 46.6593 64.7163L51.4694 63.4198C52.0186 63.2718 52.5751 63.632 52.7123 64.2244L60.7101 98.7461C60.8474 99.3385 60.5134 99.9387 59.9642 100.087L55.1541 101.383C54.605 101.531 54.0485 101.171 53.9113 100.579L45.9134 66.0569Z"
          fill="white"
        />
        <path
          d="M62.0697 64.2246C62.2066 63.6321 62.7629 63.2716 63.3122 63.4193L68.1229 64.7131C68.6721 64.8608 69.0064 65.4608 68.8694 66.0532L60.8885 100.58C60.7516 101.172 60.1953 101.533 59.646 101.385L54.8353 100.091C54.2861 99.9433 53.9518 99.3433 54.0888 98.7509L62.0697 64.2246Z"
          fill="white"
        />
        <path
          d="M65.7692 66.0569C65.6319 65.4646 65.9659 64.8644 66.5151 64.7163L71.3251 63.4198C71.8743 63.2718 72.4308 63.632 72.568 64.2244L80.5659 98.7461C80.7031 99.3385 80.3691 99.9387 79.8199 100.087L75.0099 101.383C74.4607 101.531 73.9042 101.171 73.767 100.579L65.7692 66.0569Z"
          fill="white"
        />
        <path
          d="M81.9254 64.2246C82.0624 63.6321 82.6187 63.2716 83.1679 63.4193L87.9786 64.7131C88.5279 64.8608 88.8621 65.4608 88.7252 66.0532L80.7443 100.58C80.6073 101.172 80.051 101.533 79.5018 101.385L74.6911 100.091C74.1418 99.9433 73.8076 99.3433 73.9445 98.7509L81.9254 64.2246Z"
          fill="white"
        />
        <path
          d="M63.3158 63.5118H72.028V65.1701H63.3158V63.5118Z"
          fill="white"
        />
        <path
          d="M55.6286 99.4422H59.7284V101.1H55.6286V99.4422Z"
          fill="white"
        />
        <path
          d="M75.1029 99.4422H79.2028V101.1H75.1029V99.4422Z"
          fill="white"
        />
        <path
          d="M43.1025 73.6545C43.1025 73.3493 43.332 73.1018 43.615 73.1018H90.7635C91.0465 73.1018 91.276 73.3493 91.276 73.6545V79.1823C91.276 79.4876 91.0465 79.7351 90.7635 79.7351H43.615C43.332 79.7351 43.1025 79.4876 43.1025 79.1823V73.6545Z"
          fill="white"
        />
        <defs>
          <filter
            id="filter0_d_101_9"
            x="11.2126"
            y="40.5674"
            width="114.489"
            height="84.0632"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_101_9"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_101_9"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </Wrapper>
  );
}
