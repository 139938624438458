const QUERYKEYS = {
  LOAD_ME: "loadMe",
  LOAD_BOOK_INFO: "loadBookInfo",
  LOAD_BOOK_LIST: "loadBookList",
  LOAD_MONTH_ASSET: "loadMonthAsset",
  LOAD_MONTH_ITEM: "loadMonthItem",
  LOAD_SCHEDULE: "loadSchedule",
  LOAD_CATEGORY: "loadCategory",
  LOAD_SCHEDULE_DETAIL: "loadScheduleDetail",
  LOAD_HISTORY: "loadHistory",
  LOAD_HISTORY_CATEGORY: "loadHistoryCategory",
  LOAD_DAY_HISTORY: "loadDayHistory",
  LOAD_INCOME: "loadIncome",
  LOAD_INCOME_CATEGORY: "loadIncomeCategory",
  LOAD_RECORD: "loadRecord",
  LOAD_MONTH_RECORD: "loadMonthRecord",
  LOAD_RECORD_CATEGORY: "loadRecordCategory",
  LOAD_MONTH_COMPARE_ANALYZE: "loadMonthCompareAnalyze",
  LOAD_BUDGET_COMPARE_ANALYZE: "loadBudgetCompareAnalyze",
  LOAD_WEEKLY_COMPARE_ANALYZE: "loadWeeklyCompareAnalyze",
  LOAD_FIXED_EXPENSES: "loadFixedExpenses",
  LOAD_CATEGORY_CHART: "loadCategoryChart",
  LOAD_DAILY_CHART: "loadDailyChart",
} as const;

export default QUERYKEYS;
