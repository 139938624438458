import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
`;
export default function SubLogo() {
  return (
    <Wrapper>
      <svg
        width="52"
        height="52"
        viewBox="0 0 52 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="52" height="52" rx="26" fill="#E9EEFF" />
        <path
          d="M9.31277 20.0515L16.9197 18.3764C16.0855 14.9573 13.8353 12.5758 11.7942 13.0111C9.74305 13.4485 8.65957 16.569 9.31277 20.0515Z"
          fill="#E4AD40"
        />
        <path
          d="M16.8356 18.32C15.7288 14.0035 13.1924 12.9244 12.0625 12.9244C16.1669 12.0943 24.5555 10.3787 25.2749 10.1574C25.9944 9.93602 26.5201 10.0651 26.693 10.1574C31.0649 11.2642 34.994 24.1307 36.4121 30.4257H20.0522L16.8356 18.32Z"
          fill="#FFC144"
        />
        <g filter="url(#filter0_d_60_59)">
          <path
            d="M26.2788 23.6429C28.3208 23.6429 29.9761 22.1814 29.9761 20.3785C29.9761 20.3149 29.9741 20.2517 29.97 20.189H36.41C38.1635 20.189 39.5851 21.6222 39.5851 23.3902V36.0266C39.5851 37.7946 38.1635 39.2278 36.41 39.2278H16.1058C14.3522 39.2278 12.9307 37.7946 12.9307 36.0266V23.3902C12.9307 21.6222 14.3522 20.189 16.1058 20.189H22.5875C22.5835 20.2517 22.5814 20.3149 22.5814 20.3785C22.5814 22.1814 24.2368 23.6429 26.2788 23.6429Z"
            fill="white"
          />
        </g>
        <path
          d="M20.6152 26.5694C20.5808 26.4211 20.6644 26.2709 20.8019 26.2338L22.0059 25.9093C22.1433 25.8722 22.2826 25.9624 22.317 26.1107L24.3189 34.7516C24.3532 34.8998 24.2696 35.0501 24.1321 35.0871L22.9282 35.4116C22.7907 35.4487 22.6514 35.3585 22.6171 35.2103L20.6152 26.5694Z"
          fill="#7798FC"
        />
        <path
          d="M24.6592 26.1107C24.6934 25.9624 24.8327 25.8722 24.9701 25.9092L26.1743 26.233C26.3118 26.27 26.3954 26.4201 26.3611 26.5684L24.3635 35.2105C24.3292 35.3588 24.19 35.449 24.0525 35.412L22.8484 35.0882C22.7109 35.0512 22.6272 34.901 22.6615 34.7528L24.6592 26.1107Z"
          fill="#7798FC"
        />
        <path
          d="M25.5851 26.5694C25.5508 26.4211 25.6344 26.2709 25.7718 26.2338L26.9758 25.9093C27.1133 25.8722 27.2526 25.9624 27.2869 26.1107L29.2888 34.7516C29.3231 34.8998 29.2396 35.0501 29.1021 35.0871L27.8981 35.4116C27.7607 35.4487 27.6214 35.3585 27.587 35.2103L25.5851 26.5694Z"
          fill="#7798FC"
        />
        <path
          d="M29.6291 26.1107C29.6634 25.9624 29.8026 25.8722 29.9401 25.9092L31.1442 26.233C31.2817 26.27 31.3654 26.4201 31.3311 26.5684L29.3335 35.2105C29.2992 35.3588 29.1599 35.449 29.0225 35.412L27.8183 35.0882C27.6808 35.0512 27.5972 34.901 27.6315 34.7528L29.6291 26.1107Z"
          fill="#7798FC"
        />
        <path
          d="M24.9711 25.9323H27.1518V26.3474H24.9711V25.9323Z"
          fill="#7798FC"
        />
        <path
          d="M23.0469 34.9258H24.0731V35.3409H23.0469V34.9258Z"
          fill="#7798FC"
        />
        <path
          d="M27.9214 34.9258H28.9476V35.3409H27.9214V34.9258Z"
          fill="#7798FC"
        />
        <path
          d="M19.9116 28.4711C19.9116 28.3947 19.9691 28.3327 20.0399 28.3327H31.8413C31.9121 28.3327 31.9696 28.3947 31.9696 28.4711V29.8547C31.9696 29.9311 31.9121 29.993 31.8413 29.993H20.0399C19.9691 29.993 19.9116 29.9311 19.9116 29.8547V28.4711Z"
          fill="#7798FC"
        />
        <defs>
          <filter
            id="filter0_d_60_59"
            x="8.93066"
            y="20.189"
            width="34.6543"
            height="27.0391"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_60_59"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_60_59"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </Wrapper>
  );
}
