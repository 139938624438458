import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
`;
export default function NoExistLogo() {
  return (
    <Wrapper>
      <svg
        width="124"
        height="123"
        viewBox="0 0 124 123"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.74739 39.4574L30.7133 32.8589C27.4271 19.3899 18.563 10.0086 10.5223 11.7232C2.44238 13.4463 -1.82577 25.7387 0.74739 39.4574Z"
          fill="#AAAAAA"
        />
        <path
          d="M30.3821 32.6367C26.022 15.6326 16.0303 11.3816 11.5794 11.3816C27.7479 8.11153 60.7933 1.35349 63.6273 0.48148C66.4613 -0.390526 68.5323 0.118144 69.2136 0.48148C86.4357 4.84151 101.914 55.5269 107.5 80.3245H43.0534L30.3821 32.6367Z"
          fill="#D9D9D9"
        />
        <g filter="url(#filter0_d_535_22)">
          <path
            d="M67.5784 53.6061C75.6225 53.6061 82.1435 47.8487 82.1435 40.7466C82.1435 40.4959 82.1353 40.247 82.1193 39.9999H107.488C114.396 39.9999 119.996 45.6458 119.996 52.6105V102.389C119.996 109.354 114.396 115 107.488 115H27.5039C20.596 115 14.9961 109.354 14.9961 102.389V52.6105C14.9961 45.6458 20.596 39.9999 27.5039 39.9999H53.0375C53.0215 40.247 53.0134 40.4959 53.0134 40.7466C53.0134 47.8487 59.5343 53.6061 67.5784 53.6061Z"
            fill="#7798FC"
          />
        </g>
        <path
          d="M45.2755 65.1337C45.1402 64.5496 45.4695 63.9578 46.011 63.8119L50.7538 62.5335C51.2953 62.3876 51.844 62.7427 51.9793 63.3268L59.8654 97.366C60.0007 97.9501 59.6714 98.5419 59.1299 98.6878L54.387 99.9662C53.8455 100.112 53.2969 99.757 53.1615 99.1729L45.2755 65.1337Z"
          fill="white"
        />
        <path
          d="M61.2059 63.327C61.3409 62.7428 61.8894 62.3874 62.431 62.533L67.1745 63.8087C67.716 63.9543 68.0456 64.5459 67.9106 65.1301L60.0412 99.1737C59.9062 99.7579 59.3577 100.113 58.8161 99.9677L54.0727 98.6921C53.5311 98.5464 53.2015 97.9548 53.3365 97.3707L61.2059 63.327Z"
          fill="white"
        />
        <path
          d="M64.8537 65.1337C64.7184 64.5496 65.0477 63.9578 65.5892 63.8119L70.332 62.5335C70.8735 62.3876 71.4222 62.7427 71.5575 63.3268L79.4435 97.366C79.5788 97.9501 79.2496 98.5419 78.708 98.6878L73.9652 99.9662C73.4237 100.112 72.875 99.757 72.7397 99.1729L64.8537 65.1337Z"
          fill="white"
        />
        <path
          d="M80.7841 63.327C80.9191 62.7428 81.4676 62.3874 82.0092 62.533L86.7527 63.8087C87.2943 63.9543 87.6238 64.5459 87.4888 65.1301L79.6194 99.1737C79.4844 99.7579 78.9359 100.113 78.3943 99.9677L73.6509 98.6921C73.1093 98.5464 72.7797 97.9548 72.9147 97.3707L80.7841 63.327Z"
          fill="white"
        />
        <path
          d="M62.4346 62.6242H71.0251V64.2593H62.4346V62.6242Z"
          fill="white"
        />
        <path
          d="M54.8548 98.0523H58.8974V99.6874H54.8548V98.0523Z"
          fill="white"
        />
        <path
          d="M74.057 98.0523H78.0995V99.6874H74.057V98.0523Z"
          fill="white"
        />
        <path
          d="M42.5039 72.6251C42.5039 72.3241 42.7301 72.0801 43.0092 72.0801H89.4986C89.7777 72.0801 90.0039 72.3241 90.0039 72.6251V78.0756C90.0039 78.3766 89.7777 78.6207 89.4986 78.6207H43.0092C42.7301 78.6207 42.5039 78.3766 42.5039 78.0756V72.6251Z"
          fill="white"
        />
        <defs>
          <filter
            id="filter0_d_535_22"
            x="10.9961"
            y="39.9999"
            width="113"
            height="83"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_535_22"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_535_22"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </Wrapper>
  );
}
