import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
`;
export default function Logo() {
  return (
    <Wrapper>
      <svg
        width="28"
        height="29"
        viewBox="0 0 28 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.13648 7.20524L7.6085 6.0003C7.00842 3.54076 5.38975 1.82765 3.92147 2.14076C2.446 2.45541 1.6666 4.7001 2.13648 7.20524Z"
          fill="#E4AD40"
        />
        <path
          d="M7.54802 5.95973C6.75184 2.85464 4.92727 2.07837 4.1145 2.07837C7.06699 1.48123 13.1014 0.247158 13.6189 0.0879222C14.1364 -0.0713134 14.5146 0.021574 14.639 0.0879222C17.7839 0.8841 20.6103 10.1397 21.6304 14.6679H9.86191L7.54802 5.95973Z"
          fill="#FFC144"
        />
        <g filter="url(#filter0_d_9_101)">
          <path
            d="M14.3412 9.78893C15.8101 9.78893 17.0009 8.73758 17.0009 7.44067C17.0009 7.3949 16.9994 7.34944 16.9965 7.30432H21.6291C22.8905 7.30432 23.9131 8.33532 23.9131 9.60713V18.6971C23.9131 19.9689 22.8905 20.9999 21.6291 20.9999H7.02325C5.76181 20.9999 4.73921 19.9689 4.73921 18.6971V9.60713C4.73921 8.33532 5.76181 7.30432 7.02325 7.30432H11.6859C11.683 7.34944 11.6815 7.3949 11.6815 7.44067C11.6815 8.73758 12.8723 9.78893 14.3412 9.78893Z"
            fill="#7798FC"
          />
        </g>
        <path
          d="M10.267 11.894C10.2423 11.7873 10.3024 11.6792 10.4013 11.6526L11.2674 11.4191C11.3662 11.3925 11.4664 11.4573 11.4911 11.564L12.9312 17.7798C12.9559 17.8865 12.8958 17.9946 12.7969 18.0212L11.9308 18.2547C11.8319 18.2813 11.7317 18.2165 11.707 18.1098L10.267 11.894Z"
          fill="white"
        />
        <path
          d="M13.176 11.564C13.2006 11.4574 13.3008 11.3924 13.3997 11.419L14.2659 11.652C14.3648 11.6786 14.425 11.7866 14.4003 11.8933L12.9633 18.1099C12.9387 18.2166 12.8385 18.2815 12.7396 18.2549L11.8734 18.022C11.7745 17.9954 11.7143 17.8874 11.739 17.7807L13.176 11.564Z"
          fill="white"
        />
        <path
          d="M13.8421 11.894C13.8174 11.7873 13.8775 11.6792 13.9764 11.6526L14.8425 11.4191C14.9414 11.3925 15.0416 11.4573 15.0663 11.564L16.5063 17.7798C16.531 17.8865 16.4709 17.9946 16.372 18.0212L15.5059 18.2547C15.4071 18.2813 15.3069 18.2165 15.2822 18.1098L13.8421 11.894Z"
          fill="white"
        />
        <path
          d="M16.7511 11.564C16.7758 11.4574 16.8759 11.3924 16.9748 11.419L17.841 11.652C17.9399 11.6786 18.0001 11.7866 17.9755 11.8933L16.5385 18.1099C16.5138 18.2166 16.4136 18.2815 16.3147 18.2549L15.4485 18.022C15.3496 17.9954 15.2895 17.8874 15.3141 17.7807L16.7511 11.564Z"
          fill="white"
        />
        <path
          d="M13.4004 11.4357H14.969V11.7343H13.4004V11.4357Z"
          fill="white"
        />
        <path
          d="M12.0162 17.9052H12.7544V18.2037H12.0162V17.9052Z"
          fill="white"
        />
        <path
          d="M15.5227 17.9052H16.2609V18.2037H15.5227V17.9052Z"
          fill="white"
        />
        <path
          d="M9.76085 13.262C9.76085 13.207 9.80216 13.1624 9.85312 13.1624H18.3425C18.3934 13.1624 18.4347 13.207 18.4347 13.262V14.2573C18.4347 14.3122 18.3934 14.3568 18.3425 14.3568H9.85312C9.80216 14.3568 9.76085 14.3122 9.76085 14.2573V13.262Z"
          fill="white"
        />
        <defs>
          <filter
            id="filter0_d_9_101"
            x="0.739212"
            y="7.30432"
            width="27.1739"
            height="21.6957"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_9_101"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_9_101"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </Wrapper>
  );
}

export function LargeLogo() {
  return (
    <Wrapper>
      <svg
        width="84"
        height="87"
        viewBox="0 0 28 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.13648 7.20524L7.6085 6.0003C7.00842 3.54076 5.38975 1.82765 3.92147 2.14076C2.446 2.45541 1.6666 4.7001 2.13648 7.20524Z"
          fill="#E4AD40"
        />
        <path
          d="M7.54802 5.95973C6.75184 2.85464 4.92727 2.07837 4.1145 2.07837C7.06699 1.48123 13.1014 0.247158 13.6189 0.0879222C14.1364 -0.0713134 14.5146 0.021574 14.639 0.0879222C17.7839 0.8841 20.6103 10.1397 21.6304 14.6679H9.86191L7.54802 5.95973Z"
          fill="#FFC144"
        />
        <g filter="url(#filter0_d_9_101)">
          <path
            d="M14.3412 9.78893C15.8101 9.78893 17.0009 8.73758 17.0009 7.44067C17.0009 7.3949 16.9994 7.34944 16.9965 7.30432H21.6291C22.8905 7.30432 23.9131 8.33532 23.9131 9.60713V18.6971C23.9131 19.9689 22.8905 20.9999 21.6291 20.9999H7.02325C5.76181 20.9999 4.73921 19.9689 4.73921 18.6971V9.60713C4.73921 8.33532 5.76181 7.30432 7.02325 7.30432H11.6859C11.683 7.34944 11.6815 7.3949 11.6815 7.44067C11.6815 8.73758 12.8723 9.78893 14.3412 9.78893Z"
            fill="#7798FC"
          />
        </g>
        <path
          d="M10.267 11.894C10.2423 11.7873 10.3024 11.6792 10.4013 11.6526L11.2674 11.4191C11.3662 11.3925 11.4664 11.4573 11.4911 11.564L12.9312 17.7798C12.9559 17.8865 12.8958 17.9946 12.7969 18.0212L11.9308 18.2547C11.8319 18.2813 11.7317 18.2165 11.707 18.1098L10.267 11.894Z"
          fill="white"
        />
        <path
          d="M13.176 11.564C13.2006 11.4574 13.3008 11.3924 13.3997 11.419L14.2659 11.652C14.3648 11.6786 14.425 11.7866 14.4003 11.8933L12.9633 18.1099C12.9387 18.2166 12.8385 18.2815 12.7396 18.2549L11.8734 18.022C11.7745 17.9954 11.7143 17.8874 11.739 17.7807L13.176 11.564Z"
          fill="white"
        />
        <path
          d="M13.8421 11.894C13.8174 11.7873 13.8775 11.6792 13.9764 11.6526L14.8425 11.4191C14.9414 11.3925 15.0416 11.4573 15.0663 11.564L16.5063 17.7798C16.531 17.8865 16.4709 17.9946 16.372 18.0212L15.5059 18.2547C15.4071 18.2813 15.3069 18.2165 15.2822 18.1098L13.8421 11.894Z"
          fill="white"
        />
        <path
          d="M16.7511 11.564C16.7758 11.4574 16.8759 11.3924 16.9748 11.419L17.841 11.652C17.9399 11.6786 18.0001 11.7866 17.9755 11.8933L16.5385 18.1099C16.5138 18.2166 16.4136 18.2815 16.3147 18.2549L15.4485 18.022C15.3496 17.9954 15.2895 17.8874 15.3141 17.7807L16.7511 11.564Z"
          fill="white"
        />
        <path
          d="M13.4004 11.4357H14.969V11.7343H13.4004V11.4357Z"
          fill="white"
        />
        <path
          d="M12.0162 17.9052H12.7544V18.2037H12.0162V17.9052Z"
          fill="white"
        />
        <path
          d="M15.5227 17.9052H16.2609V18.2037H15.5227V17.9052Z"
          fill="white"
        />
        <path
          d="M9.76085 13.262C9.76085 13.207 9.80216 13.1624 9.85312 13.1624H18.3425C18.3934 13.1624 18.4347 13.207 18.4347 13.262V14.2573C18.4347 14.3122 18.3934 14.3568 18.3425 14.3568H9.85312C9.80216 14.3568 9.76085 14.3122 9.76085 14.2573V13.262Z"
          fill="white"
        />
        <defs>
          <filter
            id="filter0_d_9_101"
            x="0.739212"
            y="7.30432"
            width="27.1739"
            height="21.6957"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_9_101"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_9_101"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </Wrapper>
  );
}
