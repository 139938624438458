import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
`;
export default function Empty() {
  return (
    <Wrapper>
      <svg
        width="90"
        height="90"
        viewBox="0 0 90 90"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="90" height="90" rx="45" fill="#E9EEFF" />
        <g filter="url(#filter0_d_74_31)">
          <path
            d="M45.5384 34.3496C49.2923 34.3496 52.3354 31.6628 52.3354 28.3485C52.3354 28.2315 52.3316 28.1153 52.3242 28H64.163C67.3867 28 70 30.6348 70 33.885V57.115C70 60.3652 67.3867 63 64.163 63H26.837C23.6133 63 21 60.3652 21 57.115V33.885C21 30.6348 23.6133 28 26.837 28H38.7527C38.7452 28.1153 38.7414 28.2315 38.7414 28.3485C38.7414 31.6628 41.7845 34.3496 45.5384 34.3496Z"
            fill="white"
          />
        </g>
        <path
          d="M35.1274 39.7284C35.0643 39.4558 35.2179 39.1797 35.4706 39.1115L37.6839 38.515C37.9366 38.4469 38.1927 38.6126 38.2558 38.8852L41.936 54.7701C41.9991 55.0427 41.8455 55.3189 41.5928 55.387L39.3794 55.9836C39.1267 56.0517 38.8707 55.8859 38.8075 55.6133L35.1274 39.7284Z"
          fill="#7798FC"
        />
        <path
          d="M42.5616 38.8853C42.6246 38.6127 42.8806 38.4468 43.1333 38.5147L45.3469 39.11C45.5996 39.178 45.7534 39.4541 45.6904 39.7267L42.0181 55.6137C41.9551 55.8864 41.6991 56.0522 41.4464 55.9843L39.2327 55.389C38.98 55.321 38.8262 55.0449 38.8892 54.7723L42.5616 38.8853Z"
          fill="#7798FC"
        />
        <path
          d="M44.2639 39.7284C44.2007 39.4558 44.3544 39.1797 44.6071 39.1115L46.8204 38.515C47.0731 38.4469 47.3292 38.6126 47.3923 38.8852L51.0725 54.7701C51.1356 55.0427 50.9819 55.3189 50.7292 55.387L48.5159 55.9836C48.2632 56.0517 48.0072 55.8859 47.944 55.6133L44.2639 39.7284Z"
          fill="#7798FC"
        />
        <path
          d="M51.6981 38.8853C51.7611 38.6127 52.017 38.4468 52.2698 38.5147L54.4834 39.11C54.7361 39.178 54.8899 39.4541 54.8269 39.7267L51.1546 55.6137C51.0915 55.8864 50.8356 56.0522 50.5828 55.9843L48.3692 55.389C48.1165 55.321 47.9627 55.0449 48.0257 54.7723L51.6981 38.8853Z"
          fill="#7798FC"
        />
        <path
          d="M43.135 38.5573H47.1439V39.3204H43.135V38.5573Z"
          fill="#7798FC"
        />
        <path
          d="M39.5978 55.0904H41.4843V55.8535H39.5978V55.0904Z"
          fill="#7798FC"
        />
        <path
          d="M48.5587 55.0904H50.4453V55.8535H48.5587V55.0904Z"
          fill="#7798FC"
        />
        <path
          d="M33.834 43.2244C33.834 43.0839 33.9396 42.97 34.0698 42.97H55.7648C55.8951 42.97 56.0007 43.0839 56.0007 43.2244V45.768C56.0007 45.9084 55.8951 46.0223 55.7648 46.0223H34.0698C33.9396 46.0223 33.834 45.9084 33.834 45.768V43.2244Z"
          fill="#7798FC"
        />
        <defs>
          <filter
            id="filter0_d_74_31"
            x="17"
            y="28"
            width="57"
            height="43"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_74_31"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_74_31"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </Wrapper>
  );
}
